import React from 'react';
import { Monitor, Tool } from 'react-feather';
import { Tooltip } from '@material-ui/core';

interface Props {
  isField: boolean;
  size?: string;
  color?: string;
}

export const WorkOrderTypeIcon = ({ isField, size = '12px', color = '#9C9CAA' }: Props) => {
  return (
    <Tooltip title={isField ? 'Field Work Order' : 'Office Work Order'}>
      {isField ? <Tool size={size} color={color} /> : <Monitor size={size} color={color} />}
    </Tooltip>
  );
};
