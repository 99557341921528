import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback, useMemo, useState } from 'react';
import { Clipboard, Plus } from 'react-feather';
import { hasAccess } from '@utils/roles';
import { useUserRoleSettings } from '@hooks/useRoles';
import { useAppSelector } from '@hooks/index';
import { isCustomerPortalEnabled, selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { stopPropagationClick } from '@utils/utils';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { useModal } from '@common/PromiseModal';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Switch } from '@kit/ui/Switch';
import { CreateActionItemForm } from './CreateActionItemForm';
import { CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { WorkByStages } from './WorkByStages';
import { useWork } from './useWork';
import { Placeholder } from './styled';
import { WorkItemList } from './WorkItemList';

interface Props {
  projectId: number;
}

export const Work = ({ projectId }: Props) => {
  const [isArchivedShown, setIsArchivedShown] = useState(false);
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access, isFetching: isFetchingAccess } = useUserRoleSettings(companyId, userId);
  const canViewWork = hasAccess(access, 'task', 'view');
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });

  const work = useWork(projectId, isArchivedShown);

  const allActionItemIds = useMemo(
    () => work.filter((item) => 'actionAssignees' in item).map((item) => item.id),
    [work]
  );

  const { openDrawer } = useDrawersContext();
  const { openModal } = useModal();

  const { onAddClick } = useTaskDrawer();

  const handleCreateWorkOrderClick = useCallback(() => {
    onAddClick({
      project: record
    });
  }, [onAddClick, record]);

  const createWorkMenuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Work Order',
        icon: <Clipboard size="16px" color="#9C9CAA" />,
        onClick: () => {
          handleCreateWorkOrderClick();
        }
      },
      {
        title: 'Action Item',
        icon: <FileUpIcon size="16px" color="#9C9CAA" />,
        onClick: async () => {
          const result = await openModal<number | void>(
            ({ onClose }) => <CreateActionItemForm initialRecord={record} onClose={onClose} />,
            { title: 'Create Action Item' }
          );

          if (result) {
            openDrawer(
              DrawerEntity.ACTION_ITEM,
              result,
              allActionItemIds.includes(result) ? allActionItemIds : allActionItemIds.concat(result)
            );
          }
        }
      }
    ];
  }, [handleCreateWorkOrderClick, openModal, record, openDrawer, allActionItemIds]);

  if (!record || !canViewWork || isFetchingAccess) {
    return null;
  }

  return (
    <Widget isFullWidth size="large">
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#FCF2DB">
            <Clipboard size="16px" color="#F1AA12" />
          </WidgetIcon>
          Work
          {!isPortalEnabled && hasAccess(access, 'task', 'create') && (
            <CreateWork {...stopPropagationClick}>
              <Button onClick={handleCreateWorkOrderClick} variant={ButtonVariant.Secondary}>
                <Plus size="16px" />
                Work Order
              </Button>
            </CreateWork>
          )}
          {isPortalEnabled && hasAccess(access, 'task', 'create') && (
            <CreateWork {...stopPropagationClick}>
              <ContextMenu items={createWorkMenuItems}>
                <Button variant={ButtonVariant.Secondary}>
                  <Plus size="16px" />
                  Work
                </Button>
              </ContextMenu>
            </CreateWork>
          )}
        </WidgetTitle>

        <Switch
          isActive={isArchivedShown}
          id="show_archived_work"
          label="Show archived"
          onChange={setIsArchivedShown}
        />
      </WidgetHeader>

      {record.blueprint && <WorkByStages projectId={projectId} isArchivedShown={isArchivedShown} />}

      {!record.blueprint && (!work.length ? <Placeholder>No work yet</Placeholder> : <WorkItemList work={work} />)}
    </Widget>
  );
};
