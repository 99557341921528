import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { PrivilegedTask } from '@generated/types/graphql';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { UserAvatar } from '@kit/components/UserAvatar';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Archive, Calendar, User } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import {
  Assignee,
  AvatarPlaceholder,
  LabelBorder,
  Meta,
  Row,
  StatusWrapper,
  Title,
  TitleAndIcon,
  TitleCell,
  WorkListContainer
} from './styled';

interface Props {
  work: (ActionItemListItem | PrivilegedTask)[];
}

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

export const WorkItemList = ({ work }: Props) => {
  const workOrderIds = useMemo(
    () => work.filter((item) => !('actionAssignees' in item)).map((item) => item.id),
    [work]
  );
  const actionItemIds = useMemo(() => work.filter((item) => 'actionAssignees' in item).map((item) => item.id), [work]);

  return (
    <WorkListContainer>
      {work.map((workItem) => {
        const isAtionItem = 'actionAssignees' in workItem;

        return (
          <div>
            {isAtionItem ? (
              <ActionItemRow key={`ai-${workItem.id}`} actionItem={workItem} actionItemIds={actionItemIds} />
            ) : (
              <WorkOrderRow key={`wo-${workItem.id}`} workOrder={workItem} workOrderIds={workOrderIds} />
            )}
          </div>
        );
      })}
    </WorkListContainer>
  );
};

export const ActionItemRow = ({
  actionItem,
  actionItemIds
}: {
  actionItem: ActionItemListItem;
  actionItemIds: number[];
}) => {
  const { openDrawer } = useDrawersContext();

  return (
    <Row key={`ai-${actionItem.id}`} onClick={() => openDrawer(DrawerEntity.ACTION_ITEM, actionItem.id, actionItemIds)}>
      <LabelBorder />

      <TitleCell>
        <TitleAndIcon>
          {actionItem.actionAssignees[0]?.contact && (
            <ContactAvatar size={16} contact={actionItem.actionAssignees[0].contact} />
          )}
          <FileUpIcon size="16px" color="#9C9CAA" />

          <Tooltip title={actionItem.title}>
            <Title>{actionItem.title}</Title>
          </Tooltip>
        </TitleAndIcon>
        <Meta>
          <Calendar size="12px" color="#9C9CAA" />
          {actionItem.dueDate ? formatDate(actionItem.dueDate, true) : 'No due date'}
        </Meta>
      </TitleCell>
      <StatusWrapper>
        <ActionItemStatus status={actionItem.status} isArchived={false} />
      </StatusWrapper>
    </Row>
  );
};

export const WorkOrderRow = ({ workOrder, workOrderIds }: { workOrder: PrivilegedTask; workOrderIds: number[] }) => {
  const { openDrawer } = useDrawersContext();

  const date = useMemo(() => {
    if (workOrder.isField) {
      if (!workOrder.taskVisitsByTaskIdConnection?.nodes?.length) {
        return 'No visits';
      }

      const visit =
        workOrder.taskVisitsByTaskIdConnection?.nodes?.find((visit) => !visit.isCompleted) ??
        workOrder.taskVisitsByTaskIdConnection?.nodes[0];

      return formatDate(visit.startDate, visit.startDateAllDay);
    }

    if (workOrder.endDate) {
      return formatDate(workOrder.endDate, workOrder.endDateAllDay);
    }

    if (workOrder.startDate) {
      return formatDate(workOrder.startDate, workOrder.startDateAllDay);
    }

    return 'No due date';
  }, [workOrder]);

  return (
    <Row
      isTransparent={workOrder.isArchived}
      onClick={() => openDrawer(DrawerEntity.WORK_ORDER, workOrder.id, workOrderIds)}
    >
      {workOrder.firstLabel ? (
        <Tooltip title={workOrder.firstLabel.label}>
          <LabelBorder color={workOrder.firstLabel.color} />
        </Tooltip>
      ) : (
        <LabelBorder />
      )}

      <TitleCell>
        <TitleAndIcon>
          <Assignee>
            {workOrder.assignee && <UserAvatar size={16} user={workOrder.assignee} />}

            {!workOrder.assignee && (
              <AvatarPlaceholder>
                <User size="12px" />
              </AvatarPlaceholder>
            )}
          </Assignee>

          {workOrder.isArchived && <Archive size="16px" color="#9C9CAA" />}

          <WorkOrderTypeIcon size="16px" isField={workOrder.isField} />

          <Tooltip title={workOrder.title}>
            <Title>
              #{workOrder.uid}
              {' · '}
              {workOrder.title}
            </Title>
          </Tooltip>
        </TitleAndIcon>
        <Meta>
          <Calendar size="12px" color="#9C9CAA" />
          {date}
        </Meta>
      </TitleCell>

      <StatusWrapper>
        <WorkOrderStatus status={workOrder.taskStatus} isArchived={false} />
      </StatusWrapper>
    </Row>
  );
};
