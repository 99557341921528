import React, { memo } from 'react';
import { IconProps } from 'react-feather';
import { MailIcon } from '@kit/ui/icons/Mail';
import { stopPropagationClick } from '@utils';
import { CopyButton } from '@common/ui';
import { Anchor, AnchorContainer, Copy } from '../styled';

type Props = {
  iconProps?: IconProps;
  value: string;
  isCopyAllowed?: boolean;
};

export const EmailAddress = memo(({ value, iconProps, isCopyAllowed = false }: Props) => {
  return (
    <AnchorContainer>
      <MailIcon size={12} {...iconProps} />
      <Anchor href={`mailto:${value}`} rel="noopener noreferrer" {...stopPropagationClick}>
        {value}
      </Anchor>

      {isCopyAllowed && (
        <Copy>
          <CopyButton value={value} />
        </Copy>
      )}
    </AnchorContainer>
  );
});
