import React, { memo } from 'react';
import { IconProps, Phone } from 'react-feather';
import { stopPropagationClick } from '@utils';
import { CopyButton } from '@common/ui';
import { Anchor, AnchorContainer, Copy } from '../styled';

type Props = {
  iconProps?: IconProps;
  value: string;
  iconSize?: string | number;
  isCopyAllowed?: boolean;
};

export const PhoneNumber = memo(({ value, iconSize = 12, iconProps, isCopyAllowed = false }: Props) => {
  return (
    <AnchorContainer>
      <Phone size={iconSize} {...iconProps} />
      <Anchor href={`tel:${value}`} rel="noopener noreferrer" {...stopPropagationClick}>
        {value}
      </Anchor>
      {isCopyAllowed && (
        <Copy>
          <CopyButton value={value} />
        </Copy>
      )}
    </AnchorContainer>
  );
});
