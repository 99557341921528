import { ReactQueryKey } from '@enums';
import { ActionsOrderBy, ActionStatus, PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { ActionItemListItem, useActionItemList } from '@hooks/actionItems/useActionItemList';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { useMemo } from 'react';

export const useWork = (projectId: number, isArchivedShown: boolean) => {
  const { data: workOrders = [] } = useWorkOrderList({
    queryKey: ReactQueryKey.ProjectWork,
    filters: { projectId: { equalTo: projectId }, isArchived: {} }
  });

  const { data: workOrderStatuses } = useWorkOrderStatuses();
  const statusesOrder = useMemo(() => {
    if (!workOrderStatuses) {
      return [];
    }

    const woStatuses = workOrderStatuses.map((status) => status.id);

    const actionStatusesPosition = {
      [TaskStatus.Dispatched]: ActionStatus.Requested,
      [TaskStatus.Review]: ActionStatus.InReview,
      [TaskStatus.Completed]: ActionStatus.Completed,
      [TaskStatus.Cancelled]: ActionStatus.Cancelled
    } as { [key in TaskStatus]: ActionStatus };

    return woStatuses.reduce(
      (acc, status) => {
        acc.push(status);

        if (status in actionStatusesPosition) {
          acc.push(actionStatusesPosition[status]);
        }

        return acc;
      },
      [] as (TaskStatus | ActionStatus)[]
    );
  }, [workOrderStatuses]);

  const { data: actionItems = [] } = useActionItemList(projectId, ActionsOrderBy.CreatedAtAsc);

  return useMemo(
    () =>
      sortWork(
        [...actionItems, ...workOrders.filter((workOrder) => isArchivedShown || !workOrder.isArchived)],
        statusesOrder
      ),
    [actionItems, workOrders, isArchivedShown, statusesOrder]
  );
};

export const sortWork = (
  work: (PrivilegedTask | ActionItemListItem)[],
  statusesOrder: (TaskStatus | ActionStatus)[]
) => {
  return [...work].sort((a, b) => {
    const aArchived = 'isArchived' in a ? a.isArchived : false;
    const bArchived = 'isArchived' in b ? b.isArchived : false;

    // 1) Archived to the end
    if (aArchived !== bArchived) {
      return aArchived ? 1 : -1;
    }

    // 2) Sort by status order
    const aStatus = 'taskStatus' in a ? a.taskStatus.id : a.status;
    const bStatus = 'taskStatus' in b ? b.taskStatus.id : b.status;
    const aStatusIndex = statusesOrder.indexOf(aStatus);
    const bStatusIndex = statusesOrder.indexOf(bStatus);

    if (aStatusIndex !== -1 && bStatusIndex !== -1) {
      // If both are in the order and have the same index, sort by title:
      if (aStatusIndex === bStatusIndex) {
        return a.title.localeCompare(b.title);
      }

      return aStatusIndex - bStatusIndex;
    }

    // If one status is in the order and the other isn't, prioritize the one in the order
    if (aStatusIndex !== -1) {
      return -1;
    }
    if (bStatusIndex !== -1) {
      return 1;
    }

    // 3) Fallback: sort alphabetically
    return a.title.localeCompare(b.title);
  });
};

export const useWorkGroupedByStage = (projectId: number, isArchivedShown: boolean) => {
  const work = useWork(projectId, isArchivedShown);
  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });

  return useMemo(() => {
    if (!record || !record.blueprint) {
      return {};
    }

    return work.reduce(
      (acc, workItem) => {
        const stageId =
          record.blueprint.blueprintProjectStages.find((stage) => stage.projectStage.id === workItem.projectStage?.id)
            ?.projectStage.id ?? -1;

        if (!acc[stageId]) {
          acc[stageId] = [];
        }
        acc[stageId].push(workItem);

        return acc;
      },
      {} as Record<number, typeof work>
    );
  }, [work, record]);
};
