import styled from 'styled-components';

export const LabelBorder = styled.div<{ color?: string }>`
  width: 4px;
  height: 42px;
  flex-shrink: 0;
  background-color: ${({ color }) => color ?? 'transparent'};
`;

export const Row = styled.div<{ leftBorderColor?: string; isTransparent?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding-right: 12px;
  border-bottom: 1px solid #dfdfe8;
  border-right: 1px solid #dfdfe8;
  border-left: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  opacity: ${({ isTransparent }) => (isTransparent ? 0.5 : 1)};

  &:hover {
    background-color: #e8ecef;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const WorkListContainer = styled.div`
  padding-bottom: 16px;

  > * {
    &:first-child {
      ${Row} {
        border-top: 1px solid #dfdfe8;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        ${LabelBorder} {
          border-top-left-radius: 8px;
        }
      }
    }

    &:last-child {
      ${Row} {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        ${LabelBorder} {
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
`;

export const SectionContainer = styled.div<{ isDraggingOver: boolean }>`
  border: ${({ isDraggingOver }) => (isDraggingOver ? '1px dashed #009688' : '1px solid transparent')};
  transition: border 0.15s ease-in-out;
  position: relative;
`;

export const Stage = styled.div<{ isExpandable: boolean }>`
  padding: 16px 0;
  border-top: 1px solid #dfdfe8;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: ${({ isExpandable }) => (isExpandable ? 'pointer' : 'default')};
`;

export const StageName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const Meta = styled.div`
  color: #9c9caa;
  width: 130px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  font-size: 12px;

  svg {
    flex-shrink: 0;
  }
`;

export const StatusWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
`;

export const Assignee = styled.div`
  flex: 0 0 16px;
  min-width: 16px;
  width: 16px;
  max-width: 16px;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2 !important;
  text-align: left !important;
  font-weight: 500;
  font-size: 12px;
`;

export const AvatarPlaceholder = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  color: #dfdfe8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Placeholder = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const DraggableRow = styled.div<{ isDragging: boolean; isCloneOfDragging: boolean }>`
  transform: ${(props) => !props.isDragging && 'translate(0px, 0px) !important'};
  cursor: pointer !important;
  opacity: ${(props) => props.isCloneOfDragging && '0.4'};

  > * {
    background-color: ${(props) => props.isDragging && '#E3F6F4'};
    border-radius: ${(props) => props.isDragging && '8px'};
  }

  position: relative;
  border-radius: ${({ isDragging }) => (isDragging ? '8px' : '0px')};
`;

export const SearchContainer = styled.div`
  margin-bottom: 16px;
`;

export const TitleCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
`;

export const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
